.fade-in {
    animation: fadein .5s;
    -moz-animation: fadein .5s; /* Firefox */
    -webkit-animation: fadein .5s; /* Safari and Chrome */
    -o-animation: fadein .5s; /* Opera */
}
.greentext {
    color: green;
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}